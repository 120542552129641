@import '@app/theme/variables.scss';

.cart {
    &_title,
    &_total {
        margin: 40px 0;
    }

    &_item {
        width: 100%;
        padding: 20px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 112px;
        border-top: solid $borderWidth $dark;

        &:last-of-type {
            border-bottom: solid $borderWidth $dark;
        }

        figure {
            width: 170px;
            height: 170px;
            padding: 8px;
            background-color: #fff;

            img {
                max-width: 100%;
                max-height: 100%;
                object-fit: contain;
            }
        }

        &_description {
            width: calc(100% - 170px - 35% - (112px * 2));
            display: flex;
            flex-direction: column;
            gap: 16px;
        }

        &_other {
            width: 35%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 40px;

            svg {
                cursor: pointer;

                * {
                    transition: $longTransition;
                }
            }

            &_quantity {
                display: flex;
                align-items: center;
                gap: 32px;

                &_button {
                    width: 18px;
                    height: 18px;
                    display: flex;
                    align-self: center;
                    justify-content: center;

                    &:hover {
                        * {
                            stroke: $dark;
                        }
                    }

                    &.disabled {
                        pointer-events: none;

                        .stroke {
                            stroke: $disabled;
                        }
                    }
                }
            }

            &_delete {
                width: 28px;

                &:hover {
                    * {
                        stroke: $danger;
                    }
                }
            }
        }
    }

    &_total {
        text-align: right;

        & + .button {
            display: block;
            margin-left: auto;
        }
    }

    @media only screen and (max-width: $desktopContainerWidth) {
        &_item {
            gap: 32px;

            &_description {
                width: calc(100% - 170px - 35% - (32px * 2));
            }

            &_other {
                gap: 16px;

                &_quantity {
                    gap: 16px;
                }
            }
        }
    }

    @media only screen and (max-width: $tabletContainerWidth) {
        &_item {
            padding: 16px 0;
            display: grid;
            align-items: start;
            grid-template-areas:
                'image description'
                'image other';
            grid-template-columns: 80px 1fr;
            gap: 24px;
            position: relative;

            figure {
                grid-area: image;
                width: 80px;
                height: 80px;
            }

            &_description {
                grid-area: description;
                width: 100%;
            }

            &_other {
                grid-area: other;
                width: 100%;

                &_delete {
                    position: absolute;
                    top: 16px;
                    right: 0;
                }
            }
        }

        &_total {
            margin: 24px 0 32px 0;
        }

        .button {
            width: 100%;
            margin: unset;
            padding: 16px 0;
        }
    }

    @media only screen and (max-width: 400px) {
        &_item {
            grid-template-areas:
                'image description'
                'other other';

            &_other {
                justify-content: space-around;

                &_price {
                    width: 140px;
                    text-align: right;
                }
            }
        }
    }
}
