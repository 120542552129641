@import '@app/theme/variables.scss';

.languageSelector {
    position: relative;

    &:hover {
        .languageSelector_list {
            max-height: unset;
            top: 0;
            padding-top: 28px;
            box-shadow: 0 8px 8px rgba($color: $lightDark, $alpha: 0.3);
        }
    }

    &_current {
        width: 40px;
        padding: 4px 0;
        text-transform: uppercase;
        text-align: center;
    }

    &_list {
        width: 100%;
        max-height: 0;
        position: absolute;
        top: -10%;
        overflow: hidden;
        z-index: 1;

        &,
        &_item {
            transition: $shortTransition;
        }

        &_item {
            margin: 0;
            padding: 4px 8px;
            text-align: center;
            background-color: $background;
            cursor: pointer;

            &:hover {
                background-color: rgba($color: $gold, $alpha: 1);
            }
        }
    }
}
