/** @format */

@import '@app/theme/variables.scss';

.inputRadio {
    input[type='radio'] {
        display: none;
    }

    label {
        display: inline-flex;
        align-items: center;
        gap: 8px;
        position: relative;
        cursor: pointer;

        &:hover::before {
            border-color: $lightDark;
        }

        &:has(input[type='radio']:checked)::after {
            transform: translate(-50%, -50%) scale(1);
        }

        &::before,&::after {
            content: '';
            display: inline-block;
            border-radius: 50%;
            transition: $longTransition;
        }

        &::before {
            width: 16px;
            height: 16px;
            border: $borderWidth solid $dark;
            background-color: transparent;
        }

        &::after {
            width: 10px;
            height: 10px;
            background-color: $lightDark;
            position: absolute;
            top: 50%;
            left: 8px;
            transform: translate(-50%, -50%) scale(0);
        }
    }
}
