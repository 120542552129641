@import '@app/theme/variables.scss';

.courses {
    &_form {
        &_media {
            width: 100%;
        }

        &_users {
            width: 100%;
            display: flex;
            gap: 8px;
            flex-wrap: wrap;

            &_label {
                padding: 8px;
                border: solid 1px $lightDark;
                transition: $longTransition;
                cursor: pointer;

                &:hover {
                    color: $background;
                    background-color: $lightDark;
                }
            }
        }
    }

    &_list {
        &_item {
            display: flex;
            gap: 40px;
            align-items: center;
            justify-content: space-between;

            &_title {
                width: 180px;
            }

            &_description {
                flex: 1 1 0;
            }

            &_controls {
                width: max-content;
                display: flex;
                flex-direction: column;
                gap: 8px;

                .button {
                    width: 100%;
                }
            }
        }
    }
}
