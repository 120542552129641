@import '@app/theme/variables.scss';

.search {
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    transition: $longTransition;

    &.opened {
        width: 280px;

        &::after {
            width: 100%;
            border: $borderWidth solid $lightDark;
        }

        .input {
            width: calc(280px - 32px - 24px);
            padding: 10px 16px;
            margin-right: 16px;
        }

        svg {
            margin-right: 16px;
        }
    }

    &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 0;
        height: 100%;
        transition: $shortTransition;
        z-index: 0;
    }

    .input {
        width: 0;
        padding: 10px 0;
        border: none;
        transition: $longTransition;
        z-index: 1;
    }

    svg {
        flex-shrink: 0;
    }

    &_result {
        width: 100%;
        max-height: 0;
        position: absolute;
        top: 100%;
        left: 0;
        background-color: $background;
        transition: $shortTransition;
        overflow: hidden;

        &.opened {
            max-height: 200px;
            border-right: $borderWidth solid $lightDark;
            border-bottom: $borderWidth solid $lightDark;
            border-left: $borderWidth solid $lightDark;
            overflow: auto;
            z-index: 2;
        }

        p {
            padding: 10px 16px;
            font-size: 14px;
        }

        &_item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 8px;
            transition: $shortTransition;

            &:hover {
                background-color: rgba($color: $lightDark, $alpha: 0.1);
                cursor: pointer;
            }
        }
    }
}
