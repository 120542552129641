@import '@app/theme/variables.scss';

.goods {
    &_form {
        .select {
            &_selected-option,
            &_options-list_option {
                text-transform: unset;
            }
        }

        &_media {
            width: 100%;
            display: flex;
            gap: 8px;
        }
    }

    &_list {
        &_item {
            &,
            &_other,
            &_description,
            &_other_controls {
                display: flex;
            }

            &,
            &_other {
                align-items: center;
                justify-content: space-between;
            }

            &_description,
            &_other_controls {
                flex-direction: column;
            }

            &_description {
                width: calc(100% - 120px - 35% - (40px * 2));
                gap: 16px;

                sup {
                    margin-left: 8px;
                    font-size: 12px;
                    font-weight: 400;
                }
            }

            &_other {
                width: 35%;
                gap: 40px;

                &_controls {
                    gap: 8px;

                    .button {
                        width: 100%;
                    }
                }
            }
        }
    }
}
