@import '@app/theme/variables.scss';

header {
    padding: 40px 0;
    justify-content: space-between;

    &,
    .header_controls {
        display: flex;
        align-items: center;
    }

    .logo {
        width: 100px;
        height: 100px;
    }

    .header {
        &_controls {
            gap: 32px;

            a,
            svg,
            &_control-with-quantity,
            .languageSelector {
                width: 24px;
                height: 24px;
                cursor: pointer;
            }

            &_control-with-quantity {
                position: relative;

                &_quantity {
                    width: 16px;
                    height: 16px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    top: -6px;
                    right: -6px;
                    font-size: 12px;
                    line-height: 12px;
                    border-radius: 50%;
                    background-color: $background;
                    border: solid $borderWidth $dark;
                }
            }

            svg {
                .stroke {
                    stroke: $lightDark;
                }

                .fill {
                    fill: $lightDark;
                }

                &:hover {
                    .stroke {
                        stroke: $dark;
                    }

                    .fill {
                        fill: $dark;
                    }
                }

                * {
                    transition: $longTransition;
                }
            }

            .languageSelector {
                width: unset;

                svg {
                    width: unset;
                }
            }
        }
    }

    @media only screen and (max-width: $desktopContainerWidth) {
        padding: 32px 0;

        .logo {
            width: 80px;
            height: 80px;
        }
    }

    @media only screen and (max-width: $tabletContainerWidth) {
        padding: 24px 0;

        .logo {
            width: 48px;
            height: 48px;
        }

        .header_controls {
            gap: 24px;

            a,
            svg {
                width: 20px;
                height: 20px;
                cursor: pointer;
            }
        }
    }

    @media only screen and (max-width: 570px) {
        .logo {
            &.hidden {
                width: 0;
            }
        }

        .header_controls {
            justify-content: end;

            &:has(.search.opened) {
                width: 100%;
            }

            .search.opened input {
                width: 100%;
            }
        }
    }
}

.backButton {
    width: max-content;
}
