@import '@app/theme/variables.scss';

.home {
    overflow-x: hidden;

    &_header {
        position: relative;

        &_text {
            width: 50%;
            padding-top: 128px;
            padding-bottom: 380px;

            h1,
            h2,
            h4 {
                position: relative;
                z-index: 1;
            }

            h1 {
                text-transform: uppercase;
            }

            h2 {
                margin-top: 4px;
                margin-bottom: 32px;
            }

            h4 {
                font-weight: 400;
            }

            &_image_block {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: var(--sideMargin);
                background-color: $gold;
                background-image: url('../../../../assets/images/home/header_0.png');
                background-position: bottom right;
                background-size: auto 100%;
                background-repeat: no-repeat;
                z-index: 0;

                &_controls {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                }
            }
        }

        .socialMedia {
            position: absolute;
            left: 0;
            bottom: 0;
        }
    }

    .section {
        &_title {
            margin-bottom: 40px;
            text-align: center;
        }

        &_body {
            display: flex;
            align-items: start;
            gap: 32px;

            & > a {
                width: calc((100% - var(--sideMargin)) / 2 + var(--sideMargin));
                display: block;
            }

            &_goods {
                width: calc((100% - var(--sideMargin)) / 2);
                flex-wrap: wrap;

                .card {
                    width: calc((100% - 16px) / 2);
                }
            }

            &_mobile-text {
                display: none;
                flex: 1;

                a {
                    font-weight: 500;
                    text-decoration: underline;
                }
            }
        }
    }

    .makeup .section_body_goods,
    .hair .section_body_goods,
    .care .section_body_goods {
        height: max-content;
        display: flex;
        align-items: start;
        justify-content: space-between;
        gap: 16px;
    }

    .makeup,
    .care {
        margin-left: 0;

        .section_title {
            margin-left: var(--sideMargin);
        }
    }

    .hair {
        margin-right: 0;

        .section_title {
            margin-right: var(--sideMargin);
        }
    }

    .blog_section_body {
        display: flex;
        align-items: start;
        justify-content: space-between;
        background-color: $gold;

        &_info {
            padding: 64px 20px 64px 72px;

            p {
                margin-top: 8px;
                margin-bottom: 32px;
                line-height: 150%;
            }
        }

        img {
            width: auto;
            height: 100%;
            object-fit: cover;
        }
    }

    .news {
        position: relative;
    }

    .slick-slide {
        padding: 16px;
    }

    .slick-prev,
    .slick-next {
        width: 32px;
        position: absolute;
        top: -62px;

        &.slick-disabled {
            pointer-events: none;
            opacity: 0.7;
        }
    }

    .slick-prev {
        left: unset;
        right: calc(32px + 16px + 32px);
    }

    .slick-next {
        right: 16px;
    }

    @media only screen and (max-width: $desktopContainerWidth) {
        &_header {
            margin: 0;

            &_text {
                padding-top: 246px;
                padding-left: 32px;
                padding-bottom: 96px;

                &_image_block {
                    left: 0;

                    &_controls {
                        bottom: 24px;
                        left: 32px;
                    }
                }
            }

            .socialMedia {
                display: none;
            }
        }

        .blog_section {
            margin-left: 0;
            margin-right: 0;

            &_body {
                &_info {
                    padding: 48px 24px;

                    p {
                        margin-bottom: 16px;
                    }
                }
            }

            img {
                width: 48%;
                height: auto;
            }
        }
    }

    @media only screen and (max-width: $tabletContainerWidth) {
        &_header {
            &_text {
                padding-top: 106px;
                padding-left: 24px;
                padding-bottom: 96px;

                h2 {
                    margin-bottom: 0;
                }

                h4 {
                    display: none;
                }

                &_image_block {
                    width: 100vw;

                    &_controls {
                        bottom: 24px;
                        left: 24px;
                    }
                }
            }
        }

        .section_body {
            align-items: center;
            gap: var(--sideMargin);

            &_mobile-text {
                display: flex;
                flex-direction: column;
                gap: 16px;
            }
        }

        .makeup,
        .hair,
        .care {
            .section_body_goods {
                display: none;
            }
        }

        .blog_section {
            &_body {
                position: relative;
                overflow: hidden;
                background-image: url('../../../../assets/images/home/blog.webp');
                background-size: cover;
                background-position: 30% 50%;
                background-repeat: no-repeat;

                &_info {
                    padding: 64px 24px;
                    background-color: rgba($color: $lightDark, $alpha: 0.7);
                    z-index: 1;

                    * {
                        color: $background;
                    }

                    h4 {
                        font-size: 18px;
                    }
                }

                img {
                    display: none;
                }
            }
        }
    }
}
