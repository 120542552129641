@import '@app/theme/variables.scss';

footer {
    padding: 80px 0;
    background-color: $dark;

    * {
        color: $background;
    }

    .container {
        display: flex;
        align-items: start;
        justify-content: space-between;
        flex-wrap: wrap;

        & > .column {
            width: 30%;
            gap: 24px;
        }

        .column_title {
            text-transform: uppercase;
        }

        .column_tabs {
            gap: 16px;

            a {
                width: max-content;
                position: relative;

                &:not(.link):hover {
                    &::after {
                        width: 100%;
                    }
                }

                &::after {
                    content: '';
                    width: 0;
                    height: $borderWidth;
                    position: absolute;
                    left: 0;
                    top: calc(100% + 4px);
                    background-color: $background;
                    transition: $longTransition;
                }
            }
        }
    }

    a {
        h4 {
            font-weight: 400;
        }
    }

    .shop-name {
        h1 {
            font-weight: 700;
        }

        h3 {
            margin-bottom: 8px;
        }
    }

    .link {
        text-decoration: underline;

        &.privacy {
            margin-top: 24px;
        }
    }

    .mobile {
        width: 100%;
        display: none;

        .socialMedia {
            margin-top: 32px;

            display: flex;
            flex-direction: row;
            justify-content: center;

            a {
                &:hover {
                    svg circle {
                        fill: $lightDark;
                    }
                }

                svg {
                    circle {
                        fill: $background;
                    }

                    .fill {
                        fill: $dark;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: $desktopContainerWidth) {
        padding: 64px 0;

        .mobile {
            display: block;
        }
    }

    @media only screen and (max-width: $tabletContainerWidth) {
        padding: 48px 0;

        .container {
            display: grid;
            grid-template-areas:
                'column-1 column-2'
                'column-4 column-4';
            grid-template-columns: 1fr 1fr;
            column-gap: 16px;
            row-gap: 24px;

            .column {
                width: 100%;

                &:nth-of-type(1) {
                    grid-area: column-1;
                    width: 100%;
                    gap: 16px;
                }

                &:nth-of-type(2) {
                    grid-area: column-2;
                }

                &:nth-of-type(3) {
                    display: none;
                }
            }

            .mobile {
                grid-area: column-4;

                .socialMedia {
                    margin-top: unset;
                    justify-content: start;
                }
            }
        }
    }

    @media only screen and (max-width: 500px) {
        .container {
            grid-template-columns: 1fr;
            grid-template-areas:
                'column-1'
                'column-2'
                'column-4';
            column-gap: 24px;

            .column {
                width: 100%;
                gap: 16px;
            }

            .mobile .socialMedia {
                justify-content: center;
            }
        }
    }
}
