@import '@app/theme/variables.scss';

.card {
    justify-content: space-between;
    text-align: center;
    position: relative;

    &.ended {
        img {
            filter: grayscale(1);
        }
    }

    &,
    & > a {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;
    }

    & > a,
    figure {
        width: 100%;
    }

    .sale {
        position: absolute;
        top: 8px;
        left: 8px;
        color: $danger;
        font-weight: 600;
        letter-spacing: 2px;
        z-index: 1;
    }

    figure {
        aspect-ratio: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        background-color: #fff;

        &:has(img) {
            padding: 8px;
        }

        img {
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
        }

        .forward {
            position: absolute;
            top: 8px;
            right: 8px;
            cursor: pointer;

            svg {
                width: 24px;

                .fill {
                    fill: $dark;
                }
            }

            &.filled,
            &:hover {
                * {
                    fill: $gold !important;
                }
            }

            * {
                transition: $shortTransition;
            }
        }
    }

    p {
        height: 3.4ch;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .price {
        margin-top: 8px;

        sup {
            font-size: 12px;
        }
    }

    &_bottom {
        width: 100%;

        .rating {
            margin: 0 auto 8px auto;
            display: block;
        }

        .button {
            width: 100%;
        }
    }

    @media only screen and (max-width: $desktopContainerWidth) {
        .price {
            height: 22px;
        }
    }
}
