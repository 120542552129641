@import '@app/theme/variables.scss';

.radiobutton {
    width: max-content;
    cursor: pointer;

    input[type='radio'] {
        height: 0;
        width: 0;

        & + label {
            display: flex;
            gap: 8px;
            align-items: center;
            position: relative;
            color: $dark;
            transition: $shortTransition cubic-bezier(0.4, 0, 0.23, 1);

            & > span {
                width: 20px;
                height: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                background: transparent;
                border: $borderWidth solid $lightDark;
                border-radius: 50%;
                cursor: pointer;
                transition: all $shortTransition cubic-bezier(0.4, 0, 0.23, 1);
            }
        }
    }

    input[type='radio'] + label:hover,
    input[type='radio']:focus + label {
        font-weight: 700;
    }

    input[type='radio'] + label:hover > span,
    input[type='radio']:focus + label > span {
        background: rgba(255, 255, 255, 0.1);
    }

    input[type='radio']:checked + label > span {
        border: 6px solid $disabled;
        background: $dark;
        animation: shrink-bounce 90ms cubic-bezier(0.4, 0, 0.23, 1);
    }

    @keyframes shrink-bounce {
        0% {
            transform: scale(1);
        }
        33% {
            transform: scale(0.85);
        }
        100% {
            transform: scale(1);
        }
    }
}
