/** @format */

@import '@app/theme/variables.scss';

.checkbox {
    width: max-content;
    cursor: pointer;

    input[type='checkbox'] {
        height: 0;
        width: 0;

        & + label {
            display: flex;
            gap: 8px;
            align-items: center;
            position: relative;
            color: $dark;
            transition: $shortTransition cubic-bezier(0.4, 0, 0.23, 1);

            & > span {
                width: 20px;
                height: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                background: transparent;
                border: $borderWidth solid $lightDark;
                cursor: pointer;
                transition: all $shortTransition cubic-bezier(0.4, 0, 0.23, 1);
            }
        }
    }

    input[type='checkbox'] + label:hover,
    input[type='checkbox']:focus + label {
        font-weight: 700;
    }

    input[type='checkbox'] + label:hover > span,
    input[type='checkbox']:focus + label > span {
        background: rgba(255, 255, 255, 0.1);
    }

    input[type='checkbox']:checked + label > span {
        border: 10px solid $disabled;
        animation: shrink-bounce 90ms cubic-bezier(0.4, 0, 0.23, 1);
    }

    input[type='checkbox']:checked + label > span:before {
        content: '';
        position: absolute;
        top: 10px;
        left: 4px;
        border-right: 3px solid transparent;
        border-bottom: 3px solid transparent;
        transform: rotate(45deg);
        transform-origin: 0% 100%;
        animation: checkbox-check 70ms 140ms cubic-bezier(0.4, 0, 0.23, 1)
            forwards;
    }

    @media only screen and (max-width: $desktopContainerWidth) {
        
    }
}

@keyframes shrink-bounce {
    0% {
        transform: scale(1);
    }
    33% {
        transform: scale(0.85);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes checkbox-check {
    0% {
        width: 0;
        height: 0;
        border-color: #212121;
        transform: translate3d(0, 0, 0) rotate(45deg);
    }
    33% {
        width: 8px;
        height: 0;
        transform: translate3d(0, 0, 0) rotate(45deg);
    }
    100% {
        width: 8px;
        height: 14px;
        border-color: #212121;
        transform: translate3d(0, -14px, 0) rotate(45deg);
    }
}
