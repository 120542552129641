/** @format */

@import './theme/index.scss';

*,
*::before,
*::after {
    color: $dark;
    box-sizing: border-box;
    scroll-behavior: smooth;
    font-family: 'Geometria', sans-serif;
}

a,
a:visited,
a:active {
    color: inherit;
    text-decoration: none;
}

body,
h1,
h2,
h3,
h4,
p,
figure {
    margin: 0;
}

img,
video {
    width: 100%;
    height: 100%;
}

figure {
    overflow: hidden;
}

h1 {
    font-size: 64px;
    font-weight: 700;
    @media only screen and (max-width: $desktopContainerWidth) {
        font-size: 40px;
    }
    @media only screen and (max-width: $tabletContainerWidth) {
        font-size: 28px;
    }
}

h2 {
    font-size: 40px;
    @media only screen and (max-width: $desktopContainerWidth) {
        font-size: 24px;
    }
    @media only screen and (max-width: $tabletContainerWidth) {
        font-size: 16px;
    }
}

h3 {
    font-size: 36px;
    @media only screen and (max-width: $desktopContainerWidth) {
        font-size: 24px;
    }
    @media only screen and (max-width: $tabletContainerWidth) {
        font-size: 14px;
    }
}

h4 {
    font-size: 20px;
    @media only screen and (max-width: $desktopContainerWidth) {
        font-size: 14px;
    }
}

h2,
h3,
h4 {
    font-weight: 500;
}

p,
a,
td,
li,
label,
input,
button,
textarea {
    font-size: 16px;
    font-weight: 400;

    @media only screen and (max-width: $desktopContainerWidth) {
        font-size: 14px;
    }
}

button {
    cursor: pointer;
    outline: none;
    border: none;
}

body {
    width: 100%;
    background-color: $background;
    overflow-x: hidden;

    &:has(.loader),
    &:has(.filter-controls_mobile.open) {
        overflow: hidden;
    }

    .app {
        min-height: 100vh;
        display: flex;
        flex-direction: column;

        main {
            padding-top: 40px;
            padding-bottom: 80px;
            flex: 1;
            display: flex;
            overflow-y: auto;
            overflow-x: hidden;
        }
    }

    @media only screen and (max-width: $desktopContainerWidth) {
        .app {
            main {
                padding-top: 20px;
                padding-bottom: 20px;
            }
        }
    }
}

.container {
    margin-right: var(--sideMargin);
    margin-left: var(--sideMargin);
}

section {
    margin-top: 80px;
    margin-bottom: 80px;

    @media only screen and (max-width: $desktopContainerWidth) {
        margin-top: 32px;
        margin-bottom: 32px;
    }
}

.cart,
.cardPage,
.personalArea {
    width: 100%;
}

.delivery,
.payment,
.privacy,
.agreement {
    section {
        margin-top: 40px;
        margin-bottom: 40px;

        &:first-of-type {
            margin-top: unset;
        }

        &:last-of-type {
            margin-bottom: unset;
        }
    }

    h4 {
        margin-bottom: 16px;
    }

    p {
        min-height: 20px;
        margin: 8px 0;
    }
}

input:-webkit-autofill,
textarea:-webkit-autofill {
    background-color: transparent !important;
    color: inherit !important;
    -webkit-text-fill-color: inherit !important;
    transition: background-color 100000s ease-in-out 0s;
}
