@import '@app/theme/variables.scss';

.order {
    width: 100%;

    &_main {
        margin-top: 40px;
        display: flex;
        align-items: start;
        justify-content: space-between;

        &_delivery-info {
            width: 40%;

            &_title {
                margin-bottom: 20px;
            }

            &_contact-data,
            &_delivery, &_payment {
                margin-bottom: 40px;
            }

            &_contact-data,
            &_delivery,
            &_payment {
                display: flex;
                flex-wrap: wrap;
                gap: 8px;

                .input,
                .select,
                .inputRadio {
                    width: calc((100% - 8px) / 2);
                }
            }

            &_delivery {
                .select {
                    &:nth-of-type(2),
                    &:nth-of-type(3) {
                        width: 100%;
                    }

                    &:nth-of-type(2) {
                        * {
                            text-transform: unset;
                        }
                    }
                }
            }

            &_promocode {
                display: flex;
                gap: 8px;
            }
        }

        &_products {
            width: 50%;

            &_title {
                margin-bottom: 20px;
            }

            &_item {
                padding: 20px 0;
                justify-content: space-between;
                gap: 8px;
                border-bottom: $borderWidth solid $dark;

                &:nth-of-type(1) {
                    border-top: $borderWidth solid $dark;
                }

                &,
                figure {
                    display: flex;
                    align-items: center;
                }

                figure {
                    width: 80px;
                    height: 80px;
                    justify-content: center;
                    position: relative;
                    background-color: #fff;

                    &:has(img) {
                        padding: 8px;
                    }

                    img {
                        max-width: 100%;
                        max-height: 100%;
                        object-fit: contain;
                    }
                }

                &_description {
                    flex: 1 1 0;
                }

                &_quantity {
                    width: 40px;
                    text-align: center;
                }

                &_price {
                    width: 150px;
                    text-align: right;
                }
            }

            &_prepayment {
                margin-top: 64px;
                margin-bottom: -40px;
                text-align: right;
            }

            &_total {
                margin-top: 64px;
                margin-bottom: 40px;
                text-align: right;
            }

            .button {
                margin-left: auto;
                display: block;
            }

            .google-pay-button-container {
                width: 100%;

                button {
                    margin-left: auto;
                    display: block;
                }
            }
        }
    }

    @media only screen and (max-width: $desktopContainerWidth) {
        &_main {
            &_delivery-info {
                &_contact-data,
                &_delivery,
                &_payment {
                    gap: 8px;

                    .input,
                    .select {
                        width: 100%;
                    }
                }
            }

            &_products_item {
                &_price {
                    width: 140px;
                }
            }
        }
    }

    @media only screen and (max-width: $tabletContainerWidth) {
        &_main {
            flex-wrap: wrap;
            gap: 32px;

            &_delivery-info,
            &_products {
                width: 100%;
            }
        }
    }

    @media only screen and (max-width: 500px) {
        &_main {
            &_products_item {
                display: grid;
                grid-template-areas:
                    'image description description'
                    'image quantity price';
                grid-template-columns: 80px 1fr;

                figure {
                    grid-area: image;
                }

                &_description {
                    grid-area: description;
                }

                &_quantity {
                    width: max-content;
                    grid-area: quantity;
                }

                &_price {
                    grid-area: price;
                }
            }
        }
    }
}
