/** @format */

@import '@app/theme/variables.scss';

.textarea {
    width: 100%;
    min-height: 120px;
    max-height: 300px;
    padding: 10px 16px;
    background: transparent;
    border: solid $borderWidth $lightDark;
    outline: none;
    resize: vertical;

    &::placeholder {
        color: $lightDark;
        text-transform: capitalize;
    }

    &:read-only {
        cursor: not-allowed;
    }
}
