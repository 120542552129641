/** @format */

::-webkit-scrollbar {
    width: 0.8rem;
    height: 0.8rem;
    background-color: $background;

    &-thumb {
        border-radius: 0.2rem;
    }
}

::-webkit-scrollbar-button:vertical:start:decrement {
    background: linear-gradient(120deg, $background 40%, rgba(0, 0, 0, 0) 41%),
        linear-gradient(240deg, $background 40%, rgba(0, 0, 0, 0) 41%),
        linear-gradient(0deg, $background 30%, rgba(0, 0, 0, 0) 31%);
}

::-webkit-scrollbar-button:vertical:end:increment {
    background: linear-gradient(300deg, $background 40%, rgba(0, 0, 0, 0) 41%),
        linear-gradient(60deg, $background 40%, rgba(0, 0, 0, 0) 41%),
        linear-gradient(180deg, $background 30%, rgba(0, 0, 0, 0) 31%);
}

::-webkit-scrollbar-button:horizontal:start:decrement {
    background: linear-gradient(30deg, $background 40%, rgba(0, 0, 0, 0) 41%),
        linear-gradient(150deg, $background 40%, rgba(0, 0, 0, 0) 41%),
        linear-gradient(270deg, $background 30%, rgba(0, 0, 0, 0) 31%);
}

::-webkit-scrollbar-button:horizontal:end:increment {
    background: linear-gradient(210deg, $background 40%, rgba(0, 0, 0, 0) 41%),
        linear-gradient(330deg, $background 40%, rgba(0, 0, 0, 0) 41%),
        linear-gradient(90deg, $background 30%, rgba(0, 0, 0, 0) 31%);
}

::-webkit-scrollbar-thumb,
::-webkit-scrollbar-button:vertical:end:increment,
::-webkit-scrollbar-button:vertical:start:decrement,
::-webkit-scrollbar-button:horizontal:start:decrement,
::-webkit-scrollbar-button:horizontal:end:increment {
    background-color: grey;

    &:hover {
        background-color: $dark;
    }
}
