@import '@app/theme/variables.scss';

.rating {
    display: flex;
    align-items: center;
    gap: 4px;

    .star {
        transition: fill $shortTransition ease;
        cursor: pointer;

        &.filled {
            fill: $gold;

            .stroke {
                stroke: $gold;
            } 
        }
    }
}
