/** @format */

@font-face {
    font-family: 'Geometria';
    src:
        local(''),
        url('../../assets/fonts/Geometria/geometria_regular.ttf') format('truetype');
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: 'Geometria';
    src:
        local(''),
        url('../../assets/fonts/Geometria/geometria_medium.otf') format('truetype');
    font-style: normal;
    font-weight: 500;
}

@font-face {
    font-family: 'Geometria';
    src:
        local(''),
        url('../../assets/fonts/Geometria/geometria_bold.otf') format('truetype');
    font-style: normal;
    font-weight: 700;
}
