/** @format */

@import '@app/theme/variables.scss';

.inputFile {
    width: 100%;

    .button {
        width: 100%;
    }
}
