/** @format */

@import '@app/theme/variables.scss';

.articleConstructor {
    width: 100%;
    display: flex;
    gap: 8px;

    .textarea {
        min-height: 150px;
    }

    &_controls {
        display: flex;
        flex-direction: column;
        gap: 8px;

        &_item {
            width: 42px;
            height: 42px;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            &:hover {
                .fill {
                    fill: $background;
                }
            }

            svg {
                width: 24px;
            }
        }

        .inputFile {
            display: none;
        }
    }
}
