@import '@app/theme/variables.scss';

.cardPage {
    overflow-x: hidden;

    &_main {
        display: flex;
        align-items: start;
        justify-content: space-between;

        .imageScroller {
            width: 50%;
        }

        &_other {
            &_application,
            &_additional {
                padding: 8px 0;
                border-bottom: $borderWidth solid $dark;

                &.opened {
                    pre {
                        max-height: 40vh;
                        margin-top: 16px;
                        overflow-y: auto;
                    }
                }

                &_button {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    cursor: pointer;

                    svg {
                        width: 18px;

                        * {
                            stroke: $dark;
                        }
                    }
                }

                pre {
                    max-height: 0;
                    margin: 0;
                    white-space: pre-wrap;
                    overflow: hidden;
                    transition: $longTransition;
                }
            }

            &_application {
                border-top: $borderWidth solid $dark;
            }
        }

        &_description {
            width: 45%;
            display: flex;
            flex-direction: column;
            gap: 32px;

            &_title {
                a {
                    display: inline-block;
                }
                
                h3 {
                    margin-bottom: 16px;
                }
            }

            pre {
                white-space: pre-wrap;
            }

            .rating {
                margin-top: 16px;
            }

            &_buy {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
        }
    }

    &_other {
        margin-top: 80px;

        h2 {
            margin-bottom: 40px;
            text-align: center;
        }

        &_goods {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }

    @media only screen and (max-width: $desktopContainerWidth) {
        &_main {
            &_description {
                gap: 16px;
            }
        }

        &_other {
            margin-top: 40px;
        }
    }

    @media only screen and (max-width: $tabletContainerWidth) {
        &_main {
            flex-wrap: wrap;

            .imageScroller {
                width: 50%;
                margin: auto;
            }

            &_description {
                width: 100%;
                margin-top: 24px;
                align-items: center;

                div {
                    width: 100%;
                }

                h3,
                h4 {
                    text-align: center;
                }

                h3 {
                    font-size: 18px;
                }

                .rating {
                    justify-content: center;

                    svg {
                        width: 24px;
                        height: 24px;
                    }
                }

                &_buy {
                    flex-direction: column;
                    gap: 24px;

                    .button {
                        width: 50%;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 570px) {
        &_main {
            flex-wrap: wrap;

            .imageScroller {
                width: 80%;
                margin: auto;
            }

            &_description {
                &_buy {
                    .button {
                        width: 100%;
                    }
                }
            }
        }
    }
}
