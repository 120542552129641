@import '@app/theme/variables.scss';

.courses {
    &,
    &_list,
    &_list_item_description {
        display: flex;
        flex-direction: column;
    }

    &_list {
        &_item {
            max-height: 300px;
            display: flex;
            align-items: start;
            gap: 8px;

            figure {
                width: 80px;
                height: 80px;

                video {
                    object-fit: cover;
                }
            }

            &_description {
                gap: 16px;
            }

            &_controls {
                width: max-content;
                display: flex;
                flex-direction: column;
                gap: 8px;

                .button {
                    width: 100%;
                }
            }
        }

        &_empty {
            text-align: center;
        }
    }

    &_modal {
        width: 0;
        height: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 50%;
        left: 50%;
        background-color: rgba($color: $dark, $alpha: 0.7);
        z-index: 1;
        transition: $longTransition;
        overflow: hidden;
        cursor: pointer;

        &.open {
            width: 100vw;
            height: 100vh;
            top: 0;
            left: 0;
            z-index: 1;
        }

        svg {
            width: 32px;
            position: absolute;
            top: 32px;
            right: 32px;

            .stroke {
                stroke: $background;
            }
        }

        figure {
            width: 80%;

            video {
                object-fit: contain;
            }
        }
    }

    @media only screen and (max-width: 570px) {
        &_list {
            &_item {
                display: grid;
                grid-template-areas:
                    'image description'
                    'image controls';
                grid-template-columns: 80px 1fr;
                gap: 16px;

                figure {
                    grid-area: image;
                    width: 80px !important;
                    height: 80px !important;
                }

                &_description {
                    grid-area: description;
                    gap: 8px;
                }

                &_controls {
                    grid-area: controls;
                }
            }
        }
    }
}
