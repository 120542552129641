@import '@app/theme/variables.scss';

.portal-notifications {
    display: flex;
    flex-direction: column;
    gap: 8px;
    position: fixed;
    right: 16px;
    bottom: 16px;

    .notification {
        width: 300px;
        max-height: 300px;
        padding: 16px 32px;
        color: $background;
        background-color: $lightDark;

        &.type-success {
            background-color: #008314;
        }

        &.type-warning {
            background-color: $gold;
        }

        &.type-error {
            background-color: $danger;
        }
    }
}
