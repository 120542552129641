@import '@app/theme/variables.scss';

.socialMedia {
    display: flex;
    flex-direction: column;
    gap: 16px;

    a {
        width: 32px;

        &:hover {
            svg circle {
                fill: $dark;
            }
        }

        svg circle {
            transition: $longTransition;
        }
    }
}
