/** @format */

@import '@app/theme/variables.scss';

.select {
    width: 100%;
    position: relative;
    background-color: transparent;
    outline: none;
    cursor: pointer;

    &.open {
        .select_options-list {
            max-height: 120px;
            top: calc(100% + $borderWidth);
            z-index: 2;
        }
    }

    &.disabled {
        cursor: default;
        pointer-events: none;
        border-color: $disabled;

        .select_selected-option {
            span {
                color: $disabled;
            }

            .arrow {
                &::before,
                &::after {
                    background-color: $disabled;
                }
            }
        }
    }

    &,
    &_options-list_option {
        padding: 10px 16px;
    }

    &,
    &_options-list {
        border: solid $borderWidth $lightDark;
    }

    &_selected-option {
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-transform: lowercase;

        ::first-letter {
            text-transform: uppercase;
        }

        .arrow {
            display: flex;

            &::before,
            &::after {
                content: '';
                width: 12px;
                height: $borderWidth;
                position: relative;
                background-color: $lightDark;
                transition: transform $shortTransition;
            }

            &::before {
                position: relative;
                left: 4px;
                transform: rotate(45deg);
            }

            &::after {
                transform: rotate(-45deg);
            }

            &.open {
                &::before {
                    transform: rotate(-45deg);
                }

                &::after {
                    transform: rotate(45deg);
                }
            }
        }
    }

    &_options-list {
        width: calc(100% + $borderWidth * 2);
        max-height: 0;
        position: absolute;
        top: 100%;
        left: calc(0px - $borderWidth);
        background-color: $background;
        border-top-width: 0;
        overflow-y: auto;
        transition: $shortTransition;
        z-index: -1;

        &_option {
            text-transform: lowercase;
            transition: $shortTransition;

            &::first-letter {
                text-transform: uppercase;
            }

            &.active,
            &:hover {
                background-color: rgba($color: $lightDark, $alpha: 0.2);
            }
        }
    }
}
