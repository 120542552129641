@import '@app/theme/variables.scss';

.breadCrumbs {
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    gap: 8px;

    h4 {
        text-transform: lowercase;

        &::first-letter {
            text-transform: uppercase;
        }
    }
}
