@import '@app/theme/variables.scss';

.catalogue {
    width: 100%;
    display: grid;
    grid-template-areas:
        'filter filter'
        'filter-controls list'
        'filter-controls button';
    grid-template-columns: max-content 1fr;
    overflow-x: hidden;

    .filter {
        margin-bottom: 40px;
        grid-area: filter;
    }

    .filter-controls {
        padding-right: 24px;
        grid-area: filter-controls;
    }

    .goodsList {
        grid-area: list;
        flex: 1;
    }

    &_pagination {
        margin-top: 40px;
        grid-area: button;

        .button {
            margin: 0 auto;
            display: block;
        }
    }

    &_empty {
        text-align: center;
    }

    @media only screen and (max-width: $tabletContainerWidth) {
        grid-template-areas:
            'filter'
            'filter-controls'
            'list'
            'button';
        grid-template-columns: 100%;

        .filter-controls {
            margin-bottom: 16px;
            padding-right: 0;
        }
    }
}
