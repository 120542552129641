@import '@app/theme/variables.scss';

.blog {
    width: 100%;
    text-align: center;

    &_list {
        display: flex;
        flex-direction: column;
        gap: 16px;

        &_item {
            display: flex;
            gap: 16px;

            iframe {
                padding: 8px;
                border: solid $borderWidth $dark;
                outline: none;

                * {
                    overflow: hidden;
                }
            }

            h4,
            &_other {
                padding: 8px 0;
            }

            &_other {
                margin-left: auto;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .button {
                    width: 100%;
                }
            }
        }
    }

    @media only screen and (max-width: $tabletContainerWidth) {
        &_list {
            &_item {
                display: grid;
                grid-template-areas:
                    'image title'
                    'image other';
                grid-template-columns: 220px max-content;
                column-gap: 32px;
                row-gap: 16px;

                iframe {
                    grid-area: image;
                    width: 100%;
                    aspect-ratio: 1.8;
                }

                h4 {
                    grid-area: title;
                    padding: 0;
                    text-align: left;
                }

                &_other {
                    grid-area: other;
                    margin: unset;
                    padding: 0;
                    gap: 16px;
                }
            }
        }
    }

    @media only screen and (max-width: 570px) {
        &_list {
            &_item {
                grid-template-columns: 180px max-content;
                gap: 8px;
            }
        }
    }
}
