@import '@app/theme/variables.scss';

.article {
    width: 100%;

    .container {
        display: flex;
        flex-direction: column;
        gap: 8px;

        h3 {
            margin-bottom: 24px;
        }

        p {
            margin-bottom: 8px;
        }

        img {
            width: auto;
            max-height: 50vh;
            margin: 0 auto 16px auto;
            display: block;
        }
    }
}
