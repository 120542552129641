@import '@app/theme/variables.scss';

nav {
    width: 360px;

    .nav_item {
        padding: 12px 0;
        display: flex;
        align-items: center;
        gap: 16px;
        border-bottom: $borderWidth solid $lightDark;
        cursor: pointer;

        * {
            transition: $longTransition;
        }

        &.active,
        &:hover {
            border-bottom-color: $dark;

            svg {
                .stroke,
                .background {
                    stroke: $dark;
                }

                .fill {
                    fill: $dark;
                }
            }

            h4 {
                color: $dark;
            }
        }

        svg {
            width: 20px;
            height: 20px;

            .stroke,
            .background {
                stroke: $lightDark;
            }

            .fill {
                fill: $lightDark;
            }
        }

        h4 {
            color: $lightDark;
        }
    }

    @media only screen and (max-width: $desktopContainerWidth) {
        width: 200px;
    }

    @media only screen and (max-width: $tabletContainerWidth) {
        width: 40px;
        display: flex;
        flex-direction: column;
        gap: 8px;

        .nav_item {
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: solid $borderWidth $lightDark;
            border-radius: 50%;
            transition: $longTransition;

            &.active,
            &:hover {
                background-color: rgba($color: $lightDark, $alpha: 0.15);
            }

            svg {
                .stroke {
                    stroke: $dark;
                }

                .background,
                .fill {
                    fill: $dark;
                }
            }

            h4 {
                display: none;
            }
        }
    }
}
