/** @format */

.row {
    display: flex;
}

.column {
    display: flex;
    flex-direction: column;
}

.align-items-center {
    align-items: center;
}

.justify-content-center {
    justify-content: center;
}

.justify-content-between {
    justify-content: space-between;
}
