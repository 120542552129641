@import '@app/theme/variables.scss';

.articles {
    &_form {
        &_languageSelector {
            width: 100%;
            display: flex;
            align-items: center;
            gap: 8px;
        }
    }

    &_list {
        &_item {
            display: flex;
            justify-content: space-between;

            &_controls {
                display: flex;
                flex-direction: column;
                gap: 8px;

                .button {
                    width: 100%;
                }
            }
        }
    }

    &_empty {
        text-align: center;
    }
}
