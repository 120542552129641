/** @format */

.m-0 {
    margin: 0;
}

.m-1 {
    margin: 0.25rem;
}

.m-2 {
    margin: 0.5rem;
}

.m-3 {
    margin: 1rem;
}

.m-4 {
    margin: 1.5rem;
}

.m-5 {
    margin: 2rem;
}

.m-6 {
    margin: 3rem;
}

.m-7 {
    margin: 5rem;
}

.mx-1 {
    margin-right: 0.25rem;
    margin-left: 0.25rem;
}

.mx-2 {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
}

.mx-3 {
    margin-right: 1rem;
    margin-left: 1rem;
}

.mx-4 {
    margin-right: 1.5rem;
    margin-left: 1.5rem;
}

.mx-5 {
    margin-right: 2rem;
    margin-left: 2rem;
}

.my-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
}

.my-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.my-3 {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.my-4 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}

.my-5 {
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.mb-1 {
    margin-bottom: 0.25rem;
}

.mb-2 {
    margin-bottom: 0.5rem;
}

.mt-1 {
    margin-top: 0.25rem;
}

.mt-2 {
    margin-top: 0.5rem;
}

.mt-3 {
    margin-top: 1rem;
}

.mt-4 {
    margin-top: 1.5rem;
}

.mt-5 {
    margin-top: 2rem;
}

.ml-1 {
    margin-left: 0.25rem;
}

.ml-2 {
    margin-left: 0.5rem;
}

.mr-1 {
    margin-right: 0.25rem;
}

.mr-2 {
    margin-right: 0.5rem;
}

.ml-auto {
    margin-left: auto;
}
