@import '@app/theme/variables.scss';

.settings {
    &_form {
        &_media {
            width: 120px;
            height: 120px;
            margin: 0 auto 8px auto;
            border-radius: 50%;

            // &::before {
            //     content: '';
            //     position: absolute;
            //     top: 0;
            //     left: 0;
            //     width: 100%;
            //     height: 100%;
            //     background: radial-gradient(
            //       ellipse at center,
                  
            //       rgba(0, 0, 0, 0) 70%,
            //       rgba(0, 0, 0, 0.5) 100%,
            //     );
            //     pointer-events: none;
            //     z-index: 1;
            //   }
        }

        &_inputs {
            display: flex;
            flex-wrap: wrap;
            gap: 8px;

            input,
            .select,
            .inputFile {
                width: calc((100% - 8px) / 2);
            }
        }

        & > .button {
            margin: 8px auto 0 auto;
            display: block;
        }
    }

    @media only screen and (max-width: 570px) {
        &_form {
            &_media {
                width: 80px;
                height: 80px;
                margin-bottom: 16px;
            }

            &_inputs {    
                input,
                .select,
                .inputFile {
                    width: 100%;
                }
            }
        }
    }
}
