@import '@app/theme/variables.scss';

.manufacturers {
    &_form {
        .input {
            flex: 1;
        }
    }

    &_list {
        &_item {
            display: flex;
            gap: 40px;
            align-items: center;
            justify-content: space-between;

            &_title {
                width: 180px;
            }

            &_controls {
                width: max-content;
                display: flex;
                flex-direction: column;
                gap: 8px;

                .button {
                    width: 100%;
                }
            }
        }
    }
}
