@import '@app/theme/variables.scss';

.orders {
    &_list {
        &_item {
            display: flex;
            flex-direction: column;

            &.opened {
                .orders_list_item_details {
                    max-height: 500px;
                    margin-top: 16px;
                    overflow-y: auto;

                    &_goods-list {
                        max-height: 300px;
                        overflow-y: auto;
                    }
                }
            }

            &_summary,
            &_details_delivery-payment,
            &_details_goods-list_item {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            &_summary,
            &_details_delivery-payment {
                &_column {
                    &:nth-of-type(1) {
                        width: 300px;
                    }

                    &:nth-of-type(2) {
                        width: 180px;
                    }

                    &_quantity {
                        margin-top: 8px;
                    }

                    &_number {
                        text-align: right;
                    }
                }
            }

            &_summary {
                width: 100%;
                cursor: pointer;

                &:has(.canceled) {
                    * {
                        color: $lightDark;
                    }
                }

                .new,
                .payed {
                    font-weight: 600;
                }

                .warning {
                    color: $gold;
                }

                .canceled {
                    color: $danger;
                }
            }

            &_details {
                max-height: 0;
                overflow: hidden;
                transition: $longTransition;

                &_delivery-payment {
                }

                &_goods-list {
                    max-height: 0;
                    margin-top: 8px;
                    overflow: hidden;

                    &_item {
                        padding: 8px 0;
                        gap: 20px;

                        &_description {
                            flex: 1 1 0;

                            h4 {
                                margin-bottom: 8px;
                            }
                        }

                        &_price {
                            width: 94px;
                            text-align: center;
                        }
                    }
                }

                &_controls {
                    margin-top: 8px;
                    display: flex;
                    justify-content: end;
                    gap: 8px;
                }
            }
        }
    }

    &_empty {
        text-align: center;
    }
}
