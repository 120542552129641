@import '@app/theme/variables.scss';

.personalArea {
    min-height: 50vh;
    display: flex;
    justify-content: space-between;
    gap: 80px;

    &_left {
        width: max-content;
        display: flex;
        flex-direction: column;
        gap: 40px;

        &_user-info {
            display: flex;
            align-items: center;
            gap: 20px;

            figure {
                width: 160px;
                height: 160px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                overflow: hidden;
            }
        }
    }

    &_main {
        flex: 1 1 0;
        position: relative;
        overflow: hidden;

        &_slide {
            width: 100%;
            height: 100%;
            position: absolute;
            transition: $longTransition;

            &:has(.courses_modal.open) {
                transform: unset;
                animation: unset;
            }

            &--exit-bottom,
            &--enter-top {
                transform: translateY(100%);
            }

            &--enter-bottom,
            &--exit-top {
                transform: translateY(-100%);
            }

            &--enter-top {
                animation: slide-in-top 0.5s forwards;
            }

            &--enter-bottom {
                animation: slide-in-bottom 0.5s forwards;
            }

            &--exit-top {
                animation: slide-out-top 0.5s forwards;
            }

            &--exit-bottom {
                animation: slide-out-bottom 0.5s forwards;
            }
        }
    }

    .projectSettings {
        &_form {
            display: flex;
            flex-wrap: wrap;
            gap: 8px;

            input,
            .select,
            .inputFile {
                width: calc((100% - 8px) / 2);
            }

            .button {
                margin: 8px auto 0 auto;
                display: block;
            }
        }
    }

    .goods,
    .articles,
    .courses,
    .promocodes,
    .manufacturers,
    .orders,
    .settings {
        figure {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            background-color: #fff;
            overflow: hidden;

            &:hover {
                .media_controls {
                    width: 100%;
                    height: 100%;
                    left: 0;
                    top: 0;
                    background-color: rgba($color: $dark, $alpha: 0.5);
                }
            }

            img,
            video {
                object-fit: cover;
            }

            .media_controls {
                width: 0;
                height: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                left: 50%;
                top: 50%;
                overflow: hidden;
                transition: $longTransition;

                &,
                svg .stroke {
                    transition: $longTransition;
                }

                svg {
                    width: 24px;
                    height: 24px;
                    cursor: pointer;

                    &:hover {
                        .stroke {
                            stroke: $danger;
                        }
                    }

                    .stroke {
                        stroke: $background;
                    }
                }
            }
        }
    }

    .goods,
    .articles,
    .courses,
    .promocodes,
    .manufacturers,
    .orders {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 16px;

        &_empty {
            text-align: center;
        }

        &_show-hide,
        &_form {
            flex-shrink: 0;
            transition: $longTransition;
        }

        &_show-hide {
            width: max-content;
            margin-left: auto;
            text-decoration: underline;
            cursor: pointer;

            &:hover {
                color: $gold;
            }
        }

        &_form {
            max-height: 0;
            flex-wrap: wrap;
            overflow-y: auto;

            &.show {
                max-height: 50vh;
            }

            input,
            .select,
            .textarea,
            .invisible {
                width: calc((100% - 8px) / 2);
            }

            .textarea {
                min-height: 64px;
                resize: none;
            }

            figure {
                width: 80px;
                height: 80px;
            }

            &,
            &_buttons {
                display: flex;
                gap: 8px;
            }

            &_buttons {
                width: 100%;
                justify-content: center;
            }

            .inputFile {
                width: max-content;
            }
        }

        &_list {
            height: 100%;
            flex-grow: 1;
            overflow-y: auto;

            &_item {
                margin-bottom: 16px;
                padding: 10px 16px;
                border-bottom: solid $borderWidth $dark;

                &:nth-of-type(1) {
                    border-top: solid $borderWidth $dark;
                }

                figure {
                    width: 120px;
                    height: 120px;
                    background-color: #fff;

                    img,
                    video {
                        object-fit: cover;
                    }
                }
            }

            &_pagination {
                .button {
                    margin: auto;
                    display: block;
                }
            }
        }
    }

    .orders,
    .goods {
        figure {
            &:has(img) {
                padding: 8px;
            }
        }
    }

    @media only screen and (max-width: $desktopContainerWidth) {
        gap: 32px;

        &_left {
            gap: 32px;

            &_user-info {
                flex-direction: column;
                gap: 8px;

                figure {
                    width: 80px;
                    height: 80px;
                }
            }
        }
    }

    @media only screen and (max-width: $tabletContainerWidth) {
        gap: 16px;

        &_left {
            gap: 16px;

            &_user-info {
                figure {
                    width: 40px;
                    height: 40px;
                }

                h4 {
                    display: none;
                }
            }
        }
    }
}

@keyframes slide-in-top {
    from {
        transform: translateY(100%);
    }
    to {
        transform: translateY(0);
    }
}

@keyframes slide-in-bottom {
    from {
        transform: translateY(-100%);
    }
    to {
        transform: translateY(0);
    }
}

@keyframes slide-out-top {
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(-100%);
    }
}

@keyframes slide-out-bottom {
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(100%);
    }
}
