@import '@app/theme/variables.scss';

.loader {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: $background;
    z-index: 100;

    &_spinner {
        position: relative;
        width: 0px;
        height: 0px;
        top: 50%;
        left: 50%;
        -webkit-animation: spin 1s infinite ease-in-out;

        &_item {
            width: 20px;
            height: 20px;
            margin-top: -10px;
            margin-left: -10px;
            position: absolute;
            top: 50%;
            left: 50%;
            border-radius: 0;
            background-color: $dark;

            &:nth-of-type(1) {
                -webkit-animation: hTop 1s infinite ease-in-out;
            }

            &:nth-of-type(2) {
                -webkit-animation: vTop 1s infinite ease-in-out;
            }

            &:nth-of-type(3) {
                -webkit-animation: hBot 1s infinite ease-in-out;
            }

            &:nth-of-type(4) {
                -webkit-animation: vBot 1s infinite ease-in-out;
            }
        }
    }
}

@keyframes hTop {
    0% {
        -webkit-transform: translate(0px, 0px);
    }
    50% {
        -webkit-transform: translate(20px, 20px);
    }
    100% {
        -webkit-transform: translate(0px, 0px);
    }
}

@keyframes vTop {
    0% {
        -webkit-transform: translate(0px, 0px);
    }
    50% {
        -webkit-transform: translate(-20px, 20px);
    }
    100% {
        -webkit-transform: translate(0px, 0px);
    }
}

@keyframes hBot {
    0% {
        -webkit-transform: translate(0px, 0px);
    }
    50% {
        -webkit-transform: translate(20px, -20px);
    }
    100% {
        -webkit-transform: translate(0px, 0px);
    }
}

@keyframes vBot {
    0% {
        -webkit-transform: translate(0px, 0px);
    }
    50% {
        -webkit-transform: translate(-20px, -20px);
    }
    100% {
        -webkit-transform: translate(0px, 0px);
    }
}

@keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(178deg);
    }
}
