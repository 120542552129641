@import '@app/theme/variables.scss';

.filter {
    &_categories {
        margin: 8px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 80px;

        h4 {
            transition: $longTransition;
            cursor: pointer;

            &.active,
            &:hover {
                color: $lightDark;
            }
        }
    }

    &_subcategories {
        max-height: 0;
        padding: 0 120px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        column-gap: 32px;
        row-gap: 20px;
        color: $background;
        background-color: $lightDark;
        overflow: hidden;
        transition: $longTransition;

        &.active {
            max-height: unset;
            padding: 60px 120px;
        }

        &_column {
            width: calc((100% - (32px * 3)) / 4);
            display: flex;
            flex-direction: column;
            gap: 20px;

            &_item {
                &_value {
                    display: flex;
                    align-items: center;
                    gap: 14px;
                    cursor: pointer;

                    span {
                        font-size: 16px;
                        color: $background;
                        text-transform: lowercase;

                        &:nth-of-type(1) {
                            width: 10px;
                        }

                        &::first-letter {
                            text-transform: uppercase;
                        }
                    }
                }

                &_subTypes {
                    width: calc(100% - 14px - 10px);
                    max-height: 0;
                    margin-left: calc(14px + 10px);
                    padding: 0;
                    display: flex;
                    flex-direction: column;
                    gap: 4px;
                    transition: 0.5s ease-in-out;

                    &.opened {
                        max-height: 400px;
                        padding: 8px 0;
                        overflow-y: auto;
                    }

                    &::-webkit-scrollbar {
                        width: 0;
                    }

                    &_item {
                        span {
                            font-size: 14px;
                            color: $background;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }

    &-controls {
        width: 250px;

        &_sorting,
        &_manufacturers,
        &_sex {
            h4 {
                margin-bottom: 8px;
            }
        }

        &_manufacturers,
        &_sex {
            max-height: 300px;
            display: flex;
            flex-direction: column;
            gap: 8px;
            overflow-y: auto;
        }

        &_manufacturers {
            margin: 24px 0;
        }

        &_sex {
        }

        &_mobile,
        &_mobile-filters {
            display: none;
        }

        &_mobile-filters {
            flex: 1;

            .button {
                width: 100%;
                height: 100%;
            }
        }

        &_mobile {
            width: 100vw;
            height: 100vh;
            padding: 10%;
            position: fixed;
            top: 0;
            left: -120%;
            background-color: $background;
            transition: $longTransition;

            &.open {
                left: 0;
                z-index: 2;
            }

            svg {
                width: 32px;
                height: 32px;
                position: absolute;
                top: 32px;
                right: 32px;
            }

            h4 {
                margin-bottom: 8px;
                font-size: 20px;
            }

            &_manufacturers,
            &_sex {
                display: flex;
                flex-direction: column;
                gap: 8px;
            }

            &_sex {
                margin-top: 24px;
            }
        }
    }

    @media only screen and (max-width: $desktopContainerWidth) {
        &_categories {
        }

        &_subcategories {
            padding: 0 32px;
            justify-content: space-between;
            column-gap: 24px;

            &.active {
                padding: 32px;
            }
        }

        &-controls {
            width: 218px;
        }
    }

    @media only screen and (max-width: $tabletContainerWidth) {
        &_categories {
            padding: 0 16px 8px 16px;
            justify-content: space-between;
            gap: 40px;
            overflow-x: auto;

            &::-webkit-scrollbar {
                width: 2px;
                height: 2px;
            }

            &::-webkit-scrollbar-button:vertical:end:increment,
            &::-webkit-scrollbar-button:vertical:start:decrement,
            &::-webkit-scrollbar-button:horizontal:start:decrement,
            &::-webkit-scrollbar-button:horizontal:end:increment {
                display: none;
            }

            h4 {
                white-space: nowrap;
            }
        }

        // &_subcategories {
        //     padding: 0 32px;
        //     justify-content: space-between;
        //     column-gap: 24px;

        //     &.active {
        //         padding: 32px;
        //     }
        // }

        &-controls {
            width: 100%;
            display: flex;
            gap: 24px;

            &_mobile,
            &_mobile-filters {
                display: unset;
            }

            &_sorting {
                flex: 1;

                h4 {
                    display: none;
                }
            }

            &_manufacturers,
            &_sex {
                display: none;
            }
        }
    }

    @media only screen and (max-width: 500px) {
        &-controls {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            gap: 8px;

            &_mobile-filters,
            &_sorting {
                width: 100%;
                flex: unset;
            }
        }
    }
}
