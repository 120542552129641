@import '@app/theme/variables.scss';

.orders {
    min-height: 50vh;

    &_list {
        &_item {
            display: flex;
            flex-direction: column;

            &.opened {
                .orders_list_item_details {
                    max-height: 500px;
                    margin-top: 16px;
                    overflow-y: auto;

                    &_goods-list {
                        max-height: 300px;
                        overflow-y: auto;
                    }
                }
            }

            &_summary,
            &_details_delivery-payment,
            &_details_goods-list_item {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            &_summary,
            &_details_delivery-payment {
                &_column {
                    &:nth-of-type(1) {
                        width: 300px;
                    }

                    &:nth-of-type(2) {
                        width: 180px;
                    }
                }
            }

            &_summary {
                width: 100%;
                cursor: pointer;

                &:has(.canceled) {
                    * {
                        color: $lightDark;
                    }
                }

                .canceled {
                    color: $danger;
                }
            }

            &_details {
                max-height: 0;
                overflow: hidden;
                transition: $longTransition;

                &_delivery-payment {
                }

                &_goods-list {
                    max-height: 0;
                    margin-top: 8px;
                    overflow: hidden;

                    &_item {
                        padding: 8px 0;
                        gap: 20px;

                        &_description {
                            flex: 1 1 0;

                            h4 {
                                margin-bottom: 8px;
                            }
                        }

                        &_price {
                            width: 94px;
                            text-align: center;
                        }
                    }
                }
            }
        }
    }

    &_empty {
        text-align: center;
    }

    @media only screen and (max-width: $tabletContainerWidth) {
        &_list {
            &_item_details_goods-list_item {
                display: grid !important;
                align-items: start !important;
                grid-template-areas:
                    'image description description'
                    'image quantity price';
                grid-template-columns: 80px max-content 1fr;
                gap: 12px !important;
                border-bottom: $borderWidth solid $dark;

                &:nth-of-type(1) {
                    border-top: $borderWidth solid $dark;
                }

                figure {
                    grid-area: image;
                    width: 80px !important;
                    height: 80px !important;
                }

                &_description {
                    grid-area: description;
                }

                &_price {
                    grid-area: quantity;
                    width: 20px !important;
                }

                h4 {
                    grid-area: price;
                }
            }
        }
    }

    @media only screen and (max-width: 570px) {
        &_list {
            &_item_details_goods-list_item {
                grid-template-columns: 64px max-content 1fr;

                figure {
                    width: 64px !important;
                    height: 64px !important;
                }
            }
        }
    }
}
