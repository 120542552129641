@import '@app/theme/variables.scss';

.backButton {
    cursor: pointer;

    &,
    svg {
        transition: $longTransition;
    }

    svg {
        margin-right: 28px;
    }

    &:hover {
        color: $gold;

        svg {
            margin-right: 32px;
            fill: $gold;
        }
    }
}
