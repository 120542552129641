@import '@app/theme/variables.scss';

.auth {
    flex: 1;
    height: 100%;
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: center;

    &_screen {
        position: relative;
        width: 360px;
        height: 300px;
        overflow: hidden;
        border: solid $borderWidth $lightDark;

        .auth,
        .signIn,
        .signUp,
        .restore,
        .confirm {
            height: 100%;
            padding: 16px 32px;
        }

        .auth,
        .signIn,
        .signUp,
        .restore {
            gap: 24px;
            align-items: center;
            justify-content: space-between;

            &,
            &_inputs {
                display: flex;
                flex-direction: column;
            }

            &_inputs {
                width: 100%;
                gap: 16px;
            }
        }

        .button {
            min-width: 50%;
        }
    }

    &_slide {
        width: 100%;
        height: 100%;
        position: absolute;
        transition: $longTransition;

        &--exit-right,
        &--enter-left {
            transform: translateX(100%);
        }

        &--enter-right,
        &--exit-left {
            transform: translateX(-100%);
        }

        &--enter-left {
            animation: slide-in-left 0.5s forwards;
        }

        &--enter-right {
            animation: slide-in-right 0.5s forwards;
        }

        &--exit-left {
            animation: slide-out-left 0.5s forwards;
        }

        &--exit-right {
            animation: slide-out-right 0.5s forwards;
        }
    }

    .link {
        cursor: pointer;
        transition: $longTransition;

        &:hover {
            color: $gold;
        }
    }
}

@keyframes slide-in-left {
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(0);
    }
}

@keyframes slide-in-right {
    from {
        transform: translateX(-100%);
    }
    to {
        transform: translateX(0);
    }
}

@keyframes slide-out-left {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-100%);
    }
}

@keyframes slide-out-right {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(100%);
    }
}
