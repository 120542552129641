/** @format */

$background: #efedea;
$dark: #2c2c2c;
$lightDark: #5e5e5e;
$disabled: #b5b5b5;
$gold: #DCC29C;
$danger: rgb(207, 0, 0);
$success: rgb(45, 207, 0);

$desktopContainerWidth: 1200px;
$tabletContainerWidth: 770px;
$mobileContainerWidth: 95vw;

:root {
    --sideMargin: calc((100vw - #{$desktopContainerWidth}) / 2);
}
@media only screen and (max-width: #{$desktopContainerWidth}) {
    :root {
        --sideMargin: calc((100vw - #{$tabletContainerWidth}) / 2);
    }
}
@media only screen and (max-width: #{$tabletContainerWidth}) {
    :root {
        --sideMargin: calc((100vw - #{$mobileContainerWidth}) / 2);
    }
}

$borderWidth: 1px;

$shortTransition: 0.1s;
$longTransition: 0.3s;
