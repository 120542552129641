/** @format */

@import '@app/theme/variables.scss';

.input {
    width: 100%;
    padding: 10px 16px;
    background: transparent;
    border: solid $borderWidth $lightDark;
    outline: none;

    &::placeholder {
        color: $lightDark;
    }

    &:read-only {
        cursor: not-allowed;
    }
}
