/** @format */

@import '@app/theme/variables.scss';

.button {
    width: max-content;
    padding: 10px 16px;
    background-color: unset;
    border: solid $borderWidth transparent;
    transition: $longTransition;

    &:disabled {
        opacity: 0.6;
        pointer-events: none;
    }

    &:not(.type-outlined) {
        color: $background;
        background-color: $lightDark;

        &:hover {
            background-color: $dark;
        }
    }

    &.type-outlined {
        color: $dark;
        border-color: $dark;

        &:hover {
            color: $background;
            background-color: $lightDark;
        }
    }

    &.size- {
        &big {
            padding: 16px 32px;
        }
    }

    @media only screen and (max-width: $desktopContainerWidth) {
        padding: 8px;
    }
}
