@import '@app/theme/variables.scss';

.imageScroller {
    display: flex;
    gap: 16px;

    figure,
    .slick-slider .slick-slide,
    .slick-slider .slick-slide > div {
        aspect-ratio: 1;
    }

    .bordered {
        figure {
            border: $borderWidth solid $dark;
        }
    }

    figure {
        border: $borderWidth solid transparent;
        background-color: #fff;

        &:has(img) {
            padding: 8px;
        }
    }

    &_scroller {
        max-width: 20%;

        .slick-slider {
            .slick-track {
                height: unset !important;
            }

            .slick-slide {
                border: unset;
                border-bottom: 16px solid transparent;

                & > div {
                    margin-left: auto;

                    &,
                    figure {
                        height: 100%;
                        margin-right: $borderWidth;
                        outline: none;
                    }

                    figure {
                        img {
                            max-width: 100%;
                            max-height: 100%;
                            object-fit: contain;
                        }
                    }
                }
            }
        }

        figure {
            border: $borderWidth solid transparent;
            transition: $longTransition;
            cursor: pointer;

            &:hover {
                border: $borderWidth solid $dark;
            }
        }
    }

    &_current {
        width: 80%;

        img {
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
        }
    }

    @media only screen and (max-width: $desktopContainerWidth) {
        width: 370px !important;
        flex-direction: column-reverse;

        &_scroller {
            max-width: unset;
        }

        .slick-slider {
            .slick-slide {
                width: 25% !important;
                height: 76px;

                & > div {
                    margin-left: unset;

                    &,
                    figure {
                        margin-right: unset;
                    }

                    figure {
                        width: 76px !important;
                        height: 76px;
                    }
                }
            }
        }

        &_current {
            width: 100%;
        }
    }

    @media only screen and (max-width: 380px) {
        width: 95% !important;

        .slick-slider {
            .slick-slide {
                height: 64px;

                & > div {
                    figure {
                        width: 64px !important;
                        height: 64px;
                    }
                }
            }
        }
    }
}
