@import '@app/theme/variables.scss';

.goodsList {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 40px;

    .card {
        width: calc((100% - (40px * 3)) / 4);
    }

    @media only screen and (max-width: $desktopContainerWidth) {
        gap: 32px;

        .card {
            width: calc((100% - (32px * 3)) / 4);
        }
    }

    @media only screen and (max-width: $tabletContainerWidth) {
        gap: 20px;

        .card {
            width: calc((100% - 20px) / 2);
        }
    }
}
